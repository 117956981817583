@font-face {
  font-family: "Rubik";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local("Rubik Light Italic"), local("Rubik-LightItalic"),
    url(./media/fonts/Rubik/Rubik-LightItalic.ttf) format("tff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Rubik";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("Rubik Italic"), local("Rubik-Italic"), url(./media/fonts/Rubik/Rubik-RegularItalic.ttf) format("tff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Rubik";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: local("Rubik Medium Italic"), local("Rubik-MediumItalic"),
    url(./media/fonts/Rubik/Rubik-MediumItalic.ttf) format("tff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Rubik";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("Rubik Bold Italic"), local("Rubik-BoldItalic"),
    url(./media/fonts/Rubik/Rubik-BoldItalic.ttf) format("tff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Rubik";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: local("Rubik Black Italic"), local("Rubik-BlackItalic"),
    url(./media/fonts/Rubik/Rubik-BlackItalic.ttf) format("tff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Rubik Light"), local("Rubik-Light"), url(./media/fonts/Rubik/Rubik-Light.ttf) format("tff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Rubik"), local("Rubik-Regular"), url(./media/fonts/Rubik/Rubik-Regular.ttf) format("tff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Rubik Medium"), local("Rubik-Medium"), url(./media/fonts/Rubik/Rubik-Medium.ttf) format("tff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Rubik Bold"), local("Rubik-Bold"), url(./media/fonts/Rubik/Rubik-Bold.ttf) format("tff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local("Rubik Black"), local("Rubik-Black"), url(./media/fonts/Rubik/Rubik-Black.ttf) format("tff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
body {
  margin: 0;
  font-size: 16px;
  font-family: "Rubik", "Roboto", "Ubuntu", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* 
1rem - 16px
1.125rem - 18px
1.5rem - 24px
2.75rem - 44px
*/

code {
  font-family: "Rubik", "Courier New", monospace;
}

.ant-modal-mask {
  background-color: rgba(8, 32, 94, 0.7) !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
