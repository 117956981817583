.header {
  // background-color: red;
  // height: 60px;
}

.side-bar {
  .menu {
    .ant-menu-item-selected {
      span {
        a {
          color: #ffffff;
        }
      }
    }
    .menu-item {
      span {
        a {
          color: #afb4b7;
        }
      }

      &:hover {
        span {
          a {
            color: #ffffff;
          }
        }
      }
    }
  }
}

.box-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
  background-color: #ddd;
}

.text-center {
  text-align: center;
}
